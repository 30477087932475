.sms-item
  border: 1px solid var(--commentColor)
  margin-top: 10px
  padding: 8px

.sms-item-header
  lost-flex-container: row
  font-size: 0.8rem
  margin-bottom: 4px

.sms-item-from
  lost-column: 1/2

.sms-item-sent-at
  lost-column: 1/2
  text-align: right

.sms-item-body
  lost-flex-container: row

.sms-item-content
  lost-column: 4/5

.sms-item-copy-button
  lost-column: 1/5
  lost-align: middle-right

.sms-item-copy-button-self
  text-align: center
  background-color: var(--headsColor)
  color: var(--baseColor)
  border: 1px solid var(--commentColor)

  &:focus
    outline: none

  &--disabled
    background-color: var(--commentColor)
