.sms-dashboard-list
  list-style: none
  padding: 0

.sms-dashboard-logout-link
  text-align: right
  display: block

.sms-dashboard-load-block
  lost-flex-container: row
  lost-align: center

.sms-dashboard-load-more
  lost-column: 1/3
  text-align: center
  background-color: var(--headsColor)
  color: var(--baseColor)
  border: 1px solid var(--commentColor)
  height: 2.6rem
