.google-sign-in-block
  lost-flex-container: row
  lost-align: center

.google-sign-in-button
  lost-column: 1/3
  text-align: center
  background-color: var(--headsColor)
  color: var(--baseColor)
  border: 1px solid var(--commentColor)
  height: 2.6rem

  &:focus
    outline: none

  &:active
    border-width: 2px

  &--disabled
    background-color: var(--commentColor)
